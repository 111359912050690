import React from 'react';
import styled from 'styled-components';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';

import ContactForm from '../../components/forms/ContactForm';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H3 from '@rotaready/frecl/build/H3';
import Text from '@rotaready/frecl/build/Text';
import FormValidationMessage from '@rotaready/frecl/build/FormValidationMessage';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 50%;
  }
`;

const Section = styled.div`
  margin: 0 0 40px 0;
`;

const Heading = styled(H3).attrs({
  uistyle: 'brand160',
})``;

const BlueText = styled(Text).attrs({
  uistyle: 'primary',
  size: 'xl',
  weight: 600,
})``;

const TextBlock = styled.div`
  margin-top: 20px;
`;

const A = styled.a`
  text-decoration: none;
  cursor: pointer;

  &:hover * {
    color: ${({ theme: { colors } }) => colors.brand160};
  }
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

class ContactUsPage extends React.Component {
  openIntercom = () => {
    Intercom('show');
  };

  render() {
    return (
      <Body header={header}>
        <SEO
          title="Contact - Rotaready"
          description="We're available 7 days a week on 02071937120 or by live chat and email. You can also visit us at 1 Hudson's Place, Victoria Station, London, SW1V 1JT."
          url="contact"
        />

        <ResponsiveContainer>
          <ContentWrapper>
            <TitleWrapper>
              <H1 uistyle="brand160" text="Contact us" />
            </TitleWrapper>

            <BodyWrapper>
              <BodyItem>
                <Section>
                  <Heading text="Email" />

                  <TextBlock>
                    <A href="mailto:team@rotaready.com"><BlueText text="team@rotaready.com" /></A>
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Call" />

                  <TextBlock>
                    <A href="tel:+441206696454"><BlueText text="+44 (0) 1206 696454" /></A>
                  </TextBlock>

                  <TextBlock>
                    <Text text="Available 9am to 5pm (GMT), Mon-Fri" />
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Live chat" />

                  <TextBlock>
                    <A onClick={this.openIntercom}><BlueText text="Click to talk with us now" /></A>
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Visit us" />

                  <TextBlock>
                    <A href="https://g.page/rotaready" target="_blank" rel="noopener noreferrer">
                      <BlueText>
                        Rotaready
                        <br />
                        1 Hudson's Place
                        <br />
                        Victoria Station
                        <br />
                        London
                        <br />
                        SW1V 1JT
                      </BlueText>
                    </A>
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Registered address" />

                  <TextBlock>
                    <Text>
                      Access UK Ltd
                      <br />
                      Armstrong Building
                      <br />
                      Oakwood Drive
                      <br />
                      Loughborough University Science & Enterprise Park
                      <br />
                      Loughborough
                      <br />
                      LE11 3QF
                    </Text>
                  </TextBlock>

                  <TextBlock>
                    <Text>
                      Company No: 02343760
                      <br />
                      VAT: GB108221356
                    </Text>
                  </TextBlock>
                </Section>
              </BodyItem>

              <BodyItem>
                <Section>
                  <FormValidationMessage uistyle="alert">
                    <Text text="Looking for Rotaready support? The form below doesn't reach our support team, but they're eager to help by phone, email or live chat!" />
                  </FormValidationMessage>
                </Section>

                <ContactForm
                  title="Get in touch"
                  commentsLabel="Let us know exactly how we can help"
                  submitLabel="Send details"
                  showComments
                  showPhone
                  source="contact_us"
                  marketoProgramName="FY24-P09-HOS-WEB-BOF-Contact-rotaready-com_contact-Rotaready"
                  label="rotaready.com/contact"
                />
              </BodyItem>
            </BodyWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default ContactUsPage;
